function showTypeForm(idForm){
  var script = document.createElement('script');
  script.src = "https://embed.typeform.com/next/embed.js";


  var div = document.createElement('div');
  div.setAttribute("id", "typeForm");
  //div.setAttribute("data-tf-sidetab", "lY2Q6SnV");
  div.setAttribute("data-tf-popup", idForm);
  div.setAttribute("data-tf-size", "50");
  div.setAttribute("data-tf-width", 320);
  div.setAttribute("data-tf-height", 400);
  //div.setAttribute("data-tf-open", "time");
  //div-setAttribute("data-tf-openValue", "5000");
  // div.setAttribute("data-tf-button-color", "#002F7A")
  // div.setAttribute("data-tf-button-text", "Feedback")
  // div.setAttribute("style", "all:unset;")
  div.setAttribute('data-tf-onClose',fnClose);
  console.log(div);
  script.append(div);

  document.getElementsByTagName('head')[0].appendChild(script);
  console.log('hecho');
}

function fnClose({ formId }) {
  alert('cerrado file');
}
